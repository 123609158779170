import type { NodePickerNode } from '@aurora/shared-client/components/nodepicker/types';
import type { ManageContentPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import type { CustomRouter } from '@aurora/shared-client/routes/useCustomRouter';
import type {
  AbuseReportedMessagesSorts,
  ArchivedMessageSortInput,
  ChildNode,
  CoreNode,
  FilterEventSorts,
  MessageSorts,
  ParentNode,
  SpamSorts,
  TopTagSorts
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  FilterEventEntityEnum,
  ModerationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type {
  FilterAbuseFormData,
  FilterFormData,
  FilterModerationFormData,
  FilterSpamFormData,
  ManageContentFilterData,
  GuideTypeFormData,
  ArchivedMessagesFilterData
} from '../../components/managecontent/types';
import { MessageListTabItem } from '../../components/messages/MessageListTabs/MessageListTabs';
import type { MessageActionMenuItemAndProps } from '../../components/messages/MessageView/types';
import {
  MessageActionMenuItem,
  RejectReason,
  ReportedBy,
  ReportedFor,
  ReportReason
} from '../../types/enums';
import { GuideType } from '../../components/guides/enums';

export interface GenericNode
  extends CoreNode,
    Omit<ParentNode, 'children' | 'descendants'>,
    Omit<ChildNode, 'ancestors'> {}

const GuideTypeParamMap: Record<GuideType, string> = {
  [GuideType.STANDARD]: 'guide',
  [GuideType.MANUAL_SORT]: 'manual'
};

/**
 * Get Guide Type from param value
 * @param paramValue param value
 * @returns guide type value
 */
export function getGuideTypeFromParamValue(paramValue: string): string {
  return Object.keys(GuideTypeParamMap).find(key => GuideTypeParamMap[key] === paramValue);
}

/**
 * Updates the query params for Content Manage Dashboard using provided values.
 * @param router
 * @param filterData
 * @param node
 * @param sorts
 * @param tab
 */
async function updateFilterQueryParams(
  router: CustomRouter<EndUserPages, EndUserQueryParams>,
  filterData: ManageContentFilterData | GuideTypeFormData | ArchivedMessagesFilterData,
  node?: NodePickerNode,
  sorts?:
    | MessageSorts
    | SpamSorts
    | AbuseReportedMessagesSorts
    | FilterEventSorts
    | TopTagSorts
    | ArchivedMessageSortInput,
  tab?: MessageListTabItem
) {
  const filterByMessageStatus =
    tab === MessageListTabItem.DRAFT
      ? (filterData as FilterFormData)?.status
      : (filterData as FilterModerationFormData)?.publishedStatus?.toLowerCase();
  const { authors } = (filterData as ManageContentFilterData) ?? {};
  let filterByConversationStyle = null;
  if ((filterData as FilterModerationFormData)?.moderationStatus === ModerationStatus.Unmoderated) {
    filterByConversationStyle =
      (filterData as FilterModerationFormData)?.conversationStyle === ConversationStyle.Occasion
        ? EntityType.EVENT
        : (filterData as FilterModerationFormData)?.conversationStyle;
  } else if (
    (filterData as FilterModerationFormData)?.moderationStatus === ModerationStatus.Rejected
  ) {
    filterByConversationStyle = (filterData as FilterModerationFormData)?.conversationStyleReject;
  }
  let foundIn = null;
  if (
    (filterData as FilterModerationFormData)?.moderationStatus === ModerationStatus.Rejected ||
    (filterData as FilterAbuseFormData)?.reportedBy === ReportedBy.MEMBER
  ) {
    foundIn = (filterData as FilterAbuseFormData)?.reportedEntityType;
  } else if ((filterData as FilterAbuseFormData)?.reportedBy === ReportedBy.CONTENT_FILTER) {
    foundIn =
      (filterData as FilterAbuseFormData)?.eventEntityType === FilterEventEntityEnum.Occasion
        ? EntityType.EVENT
        : (filterData as FilterAbuseFormData)?.eventEntityType;
  }

  await router.replaceRoute<ManageContentPageAndParams>(
    EndUserPages.ManageContentPage,
    null,
    {
      ...router.query,
      [EndUserQueryParams.SEARCH_FILTER_BY_LOCATION]: node?.id,
      [EndUserQueryParams.SEARCH_FILTER_BY_CONTENT_TYPE]:
        (filterData as FilterFormData)?.content &&
        Object.keys((filterData as FilterFormData).content).filter(
          formData => formData && (filterData as FilterFormData).content[formData]
        ),
      [EndUserQueryParams.TAB]: tab,
      [EndUserQueryParams.SEARCH_FILTER_BY_MODERATOR]: (
        filterData as FilterSpamFormData
      )?.moderator?.toLowerCase(),
      [EndUserQueryParams.SEARCH_FILTER_BY_KEYWORD]:
        (filterData as FilterSpamFormData)?.messageSubject &&
        (filterData as FilterSpamFormData)?.messageSubject !== ''
          ? (filterData as FilterSpamFormData)?.messageSubject
          : null,
      [EndUserQueryParams.SEARCH_FILTER_BY_MESSAGE_STATUS]: filterByMessageStatus,
      [EndUserQueryParams.SEARCH_FILTER_BY_REPORT_REASON]:
        (filterData as FilterAbuseFormData)?.reportReason ??
        (tab === MessageListTabItem.ABUSE ? ReportReason.ALL : null),
      [EndUserQueryParams.SEARCH_FILTER_BY_REPORTED_FOR]:
        (filterData as FilterAbuseFormData)?.reportedFor ??
        (tab === MessageListTabItem.ABUSE ? ReportedFor.ALL : null),
      [EndUserQueryParams.FILTER_BY_MODERATION_MESSAGE_STATUS]: (
        filterData as FilterModerationFormData
      )?.moderationStatus?.toLowerCase(),
      [EndUserQueryParams.FILTER_BY_REJECT_REASON]: (filterData as FilterModerationFormData)
        ?.rejectReason,
      [EndUserQueryParams.FILTER_BY_CONVERSATION_STYLE]: filterByConversationStyle,
      [EndUserQueryParams.SEARCH_FILTER_BY_AUTHOR]: authors?.map(author =>
        author.entity.id.slice(5)
      ),
      [EndUserQueryParams.SEARCH_FILTER_BY_SENDER]: (filterData as FilterAbuseFormData)?.sender
        ? (filterData as FilterAbuseFormData).sender.entity.id
        : null,
      [EndUserQueryParams.REPORTED_BY]: (filterData as FilterAbuseFormData)?.reportedBy,
      [EndUserQueryParams.FOUND_IN]: foundIn,
      [EndUserQueryParams.SEARCH_SORT_BY]:
        (sorts as MessageSorts)?.postTime?.direction?.toLowerCase() ||
        (sorts as SpamSorts)?.reportDate?.direction?.toLowerCase() ||
        (sorts as MessageSorts)?.lastRejectDate?.direction?.toLowerCase() ||
        null,
      [EndUserQueryParams.SORT_BY_REPORTED_DATE]:
        (sorts as AbuseReportedMessagesSorts)?.reportedDate?.direction?.toLowerCase() ||
        (sorts as FilterEventSorts)?.timestamp?.direction?.toLowerCase() ||
        null,
      [EndUserQueryParams.SORT_BY_REPORTERS_COUNT]: (
        sorts as AbuseReportedMessagesSorts
      )?.reportersCount?.direction?.toLowerCase(),
      [EndUserQueryParams.SORT_BY_VIEWS_COUNT]: (
        sorts as AbuseReportedMessagesSorts
      )?.viewsCount?.direction?.toLowerCase(),
      [EndUserQueryParams.SORT_BY_KUDOS_COUNT]: (
        sorts as AbuseReportedMessagesSorts
      )?.kudosCount?.direction?.toLowerCase(),
      [EndUserQueryParams.SORT_BY_REPLIES_COUNT]: (
        sorts as AbuseReportedMessagesSorts
      )?.repliesCount?.direction?.toLowerCase(),
      [EndUserQueryParams.SEARCH_FILTER_BY_FILTER_NAME]:
        (filterData as FilterAbuseFormData)?.filterName &&
        (filterData as FilterAbuseFormData)?.filterName !== ''
          ? (filterData as FilterAbuseFormData)?.filterName
          : null,
      [EndUserQueryParams.SEARCH_FILTER_BY_FILTER_ACTION]: (filterData as FilterAbuseFormData)
        ?.filterAction,
      ...((filterData as FilterAbuseFormData)?.reportedBy === ReportedBy.CONTENT_FILTER && {
        [EndUserQueryParams.SEARCH_FILTER_BY_LOCATION]: null
      }),
      [EndUserQueryParams.FILTER_BY_TYPE]:
        GuideTypeParamMap[(filterData as GuideTypeFormData)?.guideType],
      [EndUserQueryParams.SORT_BY_ARCHIVED_DATE]: (
        sorts as ArchivedMessageSortInput
      )?.archivedDate?.direction?.toLowerCase(),
      [EndUserQueryParams.SEARCH_BY_ARCHIVED_MESSAGE_AUTHOR]: (
        filterData as ArchivedMessagesFilterData
      )?.authorId?.map(author => author.entity.id.slice(5)),
      [EndUserQueryParams.SEARCH_BY_ARCHIVER]: (
        filterData as ArchivedMessagesFilterData
      )?.archivedBy?.map(archiver => archiver.entity.id.slice(5)),
      [EndUserQueryParams.SEARCH_BY_POST_TYPE]: (filterData as ArchivedMessagesFilterData)
        ?.postType,
      [EndUserQueryParams.SEARCH_BY_ARCHIVED_DATE]: (filterData as ArchivedMessagesFilterData)
        ?.archivedDate
    },
    { shallow: true }
  );
}

const rejectActionMap = {
  [RejectReason.SPAM]: MessageActionMenuItem.MARK_AS_NOT_SPAM,
  [RejectReason.ABUSE]: MessageActionMenuItem.MARK_AS_NOT_ABUSE,
  [RejectReason.OTHER]: MessageActionMenuItem.MARK_AS_APPROVED
};

/**
 * Conditionally create the action menu items
 */
function buildMenuItems(rejectReason: string): MessageActionMenuItemAndProps[] {
  if (!rejectReason) {
    return [
      { item: MessageActionMenuItem.MARK_AS_APPROVED },
      { item: MessageActionMenuItem.REJECT },
      { item: MessageActionMenuItem.SEND_MESSAGE },
      { item: MessageActionMenuItem.EDIT },
      { item: MessageActionMenuItem.EMAIL_USER },
      { item: MessageActionMenuItem.BAN_MEMBER },
      { item: MessageActionMenuItem.EDIT_BAN_MEMBER }
    ];
  } else {
    return [
      { item: rejectActionMap[rejectReason.toLowerCase()] },
      { item: MessageActionMenuItem.EMAIL_USER },
      { item: MessageActionMenuItem.BAN_MEMBER },
      { item: MessageActionMenuItem.EDIT_BAN_MEMBER }
    ];
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  updateFilterQueryParams,
  buildMenuItems
};
